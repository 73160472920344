<template>
  <q-layout
    view="hHh lpR fFf"
    class="bg-primary-lightest"
  >
    <q-page-container>
      <slot />
    </q-page-container>
  </q-layout>
</template>
